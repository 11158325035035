import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
//import styled from 'styled-components';

//const Styles = styled.div`
	//Change internal CSS
	//.navbar {
	//	background-color: red;
	//}

	//.navbar-brand, .navbar-brand .nav-link {
	//	color: #fff;

	//	&:hover{
	//		color: #000;
	//	}
	//}
//`;

class NavigationBar extends Component{
	render(){
		return (
			//<Styled>
			<React.Fragment>
				<Navbar bg="primary" variant="dark" expand="lg">
				  <Navbar.Toggle aria-controls="basic-navbar-nav" />
				  <Navbar.Collapse id="basic-navbar-nav">
				    <Nav className="mr-auto ml-lg-auto">
				      <Nav.Link as={Link} to="/">
				      	Buy/Partner Domains
				      </Nav.Link>
				      <Nav.Link as={Link} to="/about">
				      	About
				      </Nav.Link>
				      <Nav.Link as={Link} to="/apps">
				      	Apps
				      </Nav.Link>
				    </Nav>
				  </Navbar.Collapse>
				</Navbar>
			</React.Fragment>
			//</Styled>
		);
	}
}

export default NavigationBar;

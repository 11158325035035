import React, { Component } from 'react';
import { Container, Row, Col, Image, Jumbotron, Tab , Nav } from 'react-bootstrap';
import BuyForm from './components/BuyForm';
import Logo from './components/Logo';

class Buy extends Component {

	constructor (){
		super()
		this.state = {
			formSuccess : false,
			isMsgSuccess: true
		}
	}

	formSuccessHandler = (newSuccess,newMsgSuccess) =>{
		this.setState({
			formSuccess: newSuccess,
			isMsgSuccess: newMsgSuccess
		});
	}

	render(){
		return (
			<React.Fragment>
				<div className="public-pages">
					<Container>
						<Row>
							<Col lg={12} className="text-center">
								<Logo />
								<p className="lead-desc"><a href="https://contrib.com/">Proud Member of CONTRIB</a> |<a href="https://vnoc.com">Powered by VNOC</a></p>
								<br />
								<br />
							</Col>

							{/* Start:: Buy Form */}
								<Col lg={12} className={this.state.formSuccess ? ('d-none') : null }>
									<div className="wrap-box-main mb-3">
					        			<div className="bg-left-box">
					        				<BuyForm onchangeform={this.formSuccessHandler} />
				        				</div>
				        				<div className="bg-right-box justify-content-center align-items-center d-flex">
				        					<h1 className="mb-3 text-center">
				        						A great domain name is a great asset
				        					</h1>
				        					<p className="text-center">
				        						Invest in a great domain name as part of your brand strategy and take your business to the next level
				        					</p>
				        					<ul className="list-unstyled">
				        						<li>
				        							<i className="fas fa-check text-success mr-2"></i>
				        							Secure your online identity
				        						</li>
				        						<li>
				        							<i className="fas fa-check text-success mr-2"></i>
				        							Enhance your brand equity
				        						</li>
				        						<li>
				        							<i className="fas fa-check text-success mr-2"></i>
				        							Boost your credibility
				        						</li>
				        						<li>
				        							<i className="fas fa-check text-success mr-2"></i>
				        							Improve conversion rates
				        						</li>
				        					</ul>
				        					<br />
											<h2 className="mb-3 text-md-right">
												Our Process
											</h2>
											<Tab.Container id="left-tabs-example" defaultActiveKey="first">
												<Row>
													<Col lg={12}>
														<Nav variant="tabs" className="process-nav-tab">
															<Nav.Item>
																<Nav.Link eventKey="first">
																	<span className="circle-step-main">
																		<span className="circle-step-icon">
																			<i className="fas fa-cubes text-primary fa-2x"></i>
																		</span>
																		<span className="circle-step-steps">
																			STEP 1
																		</span>
																	</span>
																</Nav.Link>
															</Nav.Item>
															<Nav.Item>
																<Nav.Link eventKey="second">
																	<span className="circle-step-main">
																		<span className="circle-step-icon">
																			<i className="fas fa-rocket text-primary fa-2x"></i>
																		</span>
																		<span className="circle-step-steps">
																			STEP 2
																		</span>
																	</span>
																</Nav.Link>
															</Nav.Item>
															<Nav.Item>
																<Nav.Link eventKey="third">
																	<span className="circle-step-main">
																		<span className="circle-step-icon">
																			<i className="fas fa-university text-primary fa-2x"></i>
																		</span>
																		<span className="circle-step-steps">
																			STEP 3
																		</span>
																	</span>
																</Nav.Link>
															</Nav.Item>
														</Nav>
													</Col>
													<Col lg={12}>
														<Tab.Content className="text-center pt-3">
															<Tab.Pane eventKey="first">
																<b>
																	Select Your Domain
																</b>
																<p>
																	Search Ecorp’s database for the ultimate domain name for your business, project or brand.
																</p>
															</Tab.Pane>
															<Tab.Pane eventKey="second">
																<b>
																	Submit your best offer
																</b>
																<p>
																	Search Ecorp’s database for the ultimate domain name for your business, project or brand.
																</p>
															</Tab.Pane>
															<Tab.Pane eventKey="third">
																<b>
																	Agree to the terms
																</b>
																<p>
																	Once the price is decided, agree to the terms of the domain name sales agreement. <br />
																	Once payment has been confirmed the owner will transfer the domain to you.
																</p>
															</Tab.Pane>
														</Tab.Content>
													</Col>
												</Row>
											</Tab.Container>
				        				</div>
				        			</div>
								</Col>
							{/* End */}

							{/* Start:: Success Message */}
							{/* <Col lg={12} className={this.state.isMsgSuccess ? ('d-none') : ('text-center') }> */}
							<Col lg={12} className={this.state.isMsgSuccess ? ('d-none') : ('text-center') }>
								<img className="img-fluid mb-3" width="350" src="https://cdn.vnoc.com/icons/icon-thankyou-800x400.png" alt="thank you icon" />
								<h2 className="mb-4">for submitting your offer.</h2>
								<h4 className="mb-3 small">
									<i className="fas fa-arrow-circle-right mr-2"></i> 
									You will be receiving three (3)-emails from Contrib.
								</h4>
								<h4 className="mb-3 small">
									<i className="fa fa-arrow-circle-right mr-2"></i> 
									Please check your spam box for safe measure.
								</h4>
								<br />
								<p className="text-center">
									Contrib is our contribution platform and it allows us to get people to help contribute, <br /> make offer or partner with premium world class brands. You could check your Offer submission in your <br /><b>"My Offers Contrib App"</b>.
								</p>
								<h4><i className="fas fa-envelope"></i> Please check your email for your Contrib access.</h4>
								<br />
								<Jumbotron className="py-4 mt-3">
									<h2 className="mb-4">How It Works?</h2>
									<Row>
										<Col lg={4}>
											<h3>
												<i className="fa fa-search mb-3"></i>
												<span className="d-block">
													Step 1
												</span>
											</h3>
											<h4>
												<small>Browse the Marketplace</small>
											</h4>
											<p className="small">
												Browse the marketplace and search for sites to submit offers. 
											</p>
										</Col>
										<Col lg={4}>
											<h3>
												<i className="fa fa-file-alt mb-3"></i>
												<span className="d-block">
													Step 2
												</span>
											</h3>
											<h4>
												<small>Submit an Offer</small>
											</h4>
											<p className="small">
												Click on sumbit offer and fill up the form.
											</p>
										</Col>
										<Col lg={4}>
											<h3>
												<i className="fa fa-desktop mb-3"></i>
												<span className="d-block">
													Step 3
												</span>
											</h3>
											<h4>
												<small>View Offers</small>
											</h4>
											<p className="small">
												View all your offer applications that you have submitted to here.
											</p>
										</Col>
									</Row>
								</Jumbotron>
							</Col>
							{/* End */}

							<Col lg={12} className="py-5 mb-3">
								<hr />
							</Col>
							<Col lg={6} className="mb-3">
								<Row>
									<Col lg={8} className="text-md-right">
										<h4>
											Select Your Domain
										</h4>
										<p className="small">
											Search Ecorp’s database for the ultimate domain name for your business, project or brand.
										</p>
									</Col>
									<Col lg={4}>
										<Image fluid roundedCircle src="https://cdn.vnoc.com/icons/domain.jpg" />
									</Col>
								</Row>
							</Col>
							<Col lg={6} className="mb-3">
								<Row>
									<Col lg={4}>
										<Image fluid roundedCircle src="https://cdn.vnoc.com/icons/offer.jpg" />
									</Col>
									<Col lg={8}>
										<h4>
											Submit your best offer
										</h4>
										<p className="small">
											We'll let you know if it's too low for consideration.
										</p>
									</Col>
								</Row>
							</Col>
							<Col lg={6} className="mb-3">
								<Row>
									<Col lg={8} className="text-md-right">
										<h4>
											Agree to the terms
										</h4>
										<p className="small">
											Once the price is decided, agree to the terms of the domain name sales agreement.
										</p>
									</Col>
									<Col lg={4}>
										<Image fluid roundedCircle src="https://cdn.vnoc.com/icons/agreement.jpg" />
									</Col>
								</Row>
							</Col>
							<Col lg={6} className="mb-3">
								<Row>
									<Col lg={4}>
										<Image fluid roundedCircle src="https://cdn.vnoc.com/icons/transfer.jpg" />
									</Col>
									<Col lg={8}>
										<h4>
											Launch your website
										</h4>
										<p className="small">
											Once payment has been confirmed the owner will transfer the domain to you.
										</p>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default Buy;
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Apps from './Apps';
import Privacy from './Privacy';
import Terms from './Terms';
import Cookie from './Cookie';
import NoMatch from './NoMatch';
import Buy from './Buy';
import Partner from './Partner';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';

class App extends Component {
	render() {
	  
    return (
	  <React.Fragment>
		<Helmet>
			<link rel="canonical" href={"https://"+window.Configs.domain} />
			<title>{window.Configs.title}</title>
			<meta name="title" content={window.Configs.title} />
			<meta name="description" content={window.Configs.domain+' - '+window.Configs.description} />
			<meta name="keywords" content={window.Configs.keywords} />
			<meta name="author" content={window.Configs.domain} />
			
			<script>
			{`
				
			  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
			  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
			  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

			  ga('create', "${window.Configs.account_ga}", "${window.Configs.domain}");
			  ga('send', 'pageview');
			`}
			</script>
			<script data-ad-client="ca-pub-0390821261465417" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
			
			<script type="text/javascript">
			{`
				var _paq = window._paq || [];
				_paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
				_paq.push(["setCookieDomain", "*.${window.Configs.domain}"]);
				_paq.push(["setDomains", ["*.${window.Configs.domain}"]]);
				_paq.push(['trackPageView']);
				_paq.push(['enableLinkTracking']);
				(function() {
					var u="//www.stats.numberchallenge.com/";
					_paq.push(['setTrackerUrl', u+'matomo.php']);
					_paq.push(['setSiteId', ${window.Configs.piwik_id}]);
					var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
					g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
				})();
			`}
			</script>
			<noscript>{`<p><img src="${"//www.stats.numberchallenge.com/matomo.php?idsite="+window.Configs.piwik_id}" alt="" /></p>`}</noscript>
			<script src="https://tools.contrib.com/js/glue.js"></script>
			
		</Helmet>
        <Router>
          <NavigationBar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/apps" component={Apps} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/terms" component={Terms} />
              <Route path="/cookie" component={Cookie} />
              <Route path="/buy" component={Buy} />
              <Route path="/partner" component={Partner} />
              <Route component={NoMatch} />
            </Switch>
          <Footer />
        </Router>
      </React.Fragment>
    );
  }
}

export default App;